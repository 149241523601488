import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

const routes: Routes = [
    {
        path: '',
        pathMatch: 'prefix',
        loadChildren: () => import('../features/booking/booking.module')
            .then(mod => mod.BookingModule),
    },
    {
        path: '**',
        redirectTo: '',
    },
]
@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            bindToComponentInputs: true,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
