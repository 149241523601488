import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'

import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'

@NgModule({ declarations: [
    AppComponent,
],
bootstrap: [AppComponent],
imports: [AppRoutingModule,
    BrowserModule],
providers: [provideHttpClient(withInterceptorsFromDi())] })
export class AppModule { }
