import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/root/app.module'
import { environment } from './environments/environment'

if (environment.production) {
    enableProdMode()
}

addModalEmbedScript()

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err))


function addModalEmbedScript() {
    const script = document.createElement('script')
    script.setAttribute(
        'src',
        environment.bookingBaseUrl + '/assets/modal-embedder.js'
    )
    document.head.appendChild(script)
}
